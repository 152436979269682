.paperRoot {
  border-radius: 12px 12px 0px 0px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image {
  margin-top: 24px;
  margin-bottom: 24px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(39, 37, 34, 1);
}

.description {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #939290;
}
