.personalize {
  text-transform: none !important;
  color: #4893ff !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.active {
  background-color: rgba(72, 147, 255, 0.16) !important;
}
